.a-Ebook
{
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.a-book
{
    height: calc(100vh - 40px);
}

.a-information-page
{
    position: absolute;
    bottom: 0;
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 255 255 / 65%);
    transition: ease-out all 0.3s;
    opacity: 0;
}

.a-information-page p
{
    font-size: 18px;
    font-family: sans-serif;
    font-weight: 600;
}

.ebook_a-back-action
{
    width: 100%;
    height: 40px;
    background-color: #000;
}

.ebook_back
{
    height: 100%;
    display: flex;
}

.ebook_back-action
{
    cursor: pointer;
    display: flex;
    align-items: center;
}

.ebook_back-action p {
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
}

.ebook_back-button
{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ebook_back-button img
{
    width: 20px;
}

.ebook_a-back-action p
{
    font-size: 18px;
    color: #fff;
    padding: 0 10px;
}