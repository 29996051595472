.input-component {
    position: relative;
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    border-radius: 3px;
    align-items: center;
    height: 40px;
}

.input-component :first-child {
    margin: 0 10px;
}

.input-component .input-icon {
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.input-component img {
    width: 20px;
}

.input-component .input-eye {
    cursor: pointer;
    margin: 0 10px;
    box-sizing: border-box;
}

.input-component input {
    width: 100%;
    height: 100%;
    border-style: hidden;
    padding: 5px;
    color: #fff;
    background: transparent;
    box-sizing: border-box;
    cursor: inherit;
}