.modal-manage-fade
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9;
}
  
.modal-manage 
{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 760px;
    height: 480px;
    max-width: 90%;
    background-color: #252525;
    padding: 1.2rem;
    z-index: 10;
}

.modal-manage .close-modal-icon
{
    position: absolute;
    cursor: pointer;
    right: 2%;
    top: 3%;
}

.modal-manage .a-form
{
    width: 54%;
}

.modal-manage .a-inputs
{
    margin-bottom: 8px;
    color: white;
    font-weight: 400;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 18px;
}

.modal-manage .a-inputs p
{
    margin-bottom: 8px;
}

.modal-manage .select-epub
{
    background: #00B0FF;
    color: black;
    border-style: outset;
    border-color: #00B0FF;
    height: 38px;
    width: 100%;
    text-shadow: none;
    border-radius: 5px;
    margin-bottom: 13px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
}

.modal-manage .register-form
{
    background: #08783A;
    color: white;
    border-style: outset;
    border-color: #08783A;
    height: 38px;
    width: 100%;
    text-shadow: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
    margin-top: 20px;
}

.modal-manage .selected-file-footer
{
    display: flex;
    flex-wrap: wrap;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 59px;
    background-color: rgb(51, 51, 51,0.95);
    opacity: 1;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

.modal-manage .selected-file-footer.not-selected-file {
    height: 100%;
}

.modal-manage .a-preview
{
    position: relative;
    width: 261px;
    height: 392px;
}

.modal-manage .ebook-image-modal
{
    width: 100%;
    height: 100%;
}

.modal-manage .change-ebook-image-text
{
    text-align: center;
    font-weight: 500;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 18px;
    color: white;
    width: 100%;
    margin-bottom: 3px;
}

.modal-manage .ebook-type-image-text
{
    font-weight: 200;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 12px;
    color: white;
    text-align: center;
}

.modal-manage .selected-file
{
    font-family: "Montserrat";
    font-style: normal;
    font-size: 13px;
    color: #888888;
}

.modal-manage .close-icon
{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.modal-manage .a-buttons
{
    position: relative;
}

.modal-manage .modal-manage-content 
{
    width: 90%;
    display: flex;
    justify-content: space-between;
}