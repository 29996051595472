body {
    margin: 0;
    font-family: 'Montserrat-SemiBold';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* @font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
   url("./assets//fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: bold;
  } */

@font-face {
    font-weight: 100;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 100;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 200;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 200;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 300;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 300;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 400;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 400;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-RegularItalic.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 500;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 500;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 600;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 700;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 700;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 800;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 800;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 900;
    font-family: "Montserrat";
    font-style: normal;
    src: url("./assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-weight: 900;
    font-family: "Montserrat";
    font-style: italic;
    src: url("./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
    font-display: block;
}