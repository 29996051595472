.input-css::placeholder,
.input-css::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.btn-google {
    position: relative;
    background-color: rgba(255, 255, 255, 0.05);
    margin-bottom: 30px;
    display: flex;
    border-radius: 3px;
    align-items: center;
    width: 100%;
    height: 38px;
    cursor: pointer;
    margin-bottom: 40px;
    background-color: #fff;
    justify-content: center;
}

.icongoogle{
    position: absolute;
    left: 8%;
}

.btn-google p{
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
}

.btn-enter-login {
    background: #00B0FF;
    color: black;
    border-style: outset;
    border-color: #00B0FF;
    height: 38px;
    width: 100%;
    text-shadow: none;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 2px 2px rgba(0, 176, 255, 0.2);
    cursor: pointer;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
}

.Registrar p,
.RecuperarSenha p {
    font-weight: 400;
    font-family: "Montserrat";
    font-style: normal; 
}

textarea:focus, input:focus, select:focus {     
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0; 
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.iconMail,
.iconPassword,
.eyePassword {
    height: 18px;
    width: 30px;
    padding: 4px;
    box-sizing: border-box;
    margin: 0 5px;
}

.eyePassword {
    cursor: pointer;
}

#login {
    width: 350px;
}

input {
    display: block;

}

.input-css-login {
    width: 100%;
    height: 38px;
    border-style: hidden;
    padding: 5px;
    color: #fff;
    background: transparent;

}

.a-page-login {
    background-color: #CBCBCB;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.linha {
    height: 100vh;
    position: relative;
    flex-flow: row wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.coluna-A {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coluna-B {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.a-page-login .background-black {
    position: absolute;
    width: 53vw;
    height: 115vh;
    top: -5%;
    right: -3%;
    background-color: black;
    transform: rotate(5deg);
    background: #252525;
    box-shadow: 6px -3px 25px 15px rgba(0, 0, 0, 0.3);
}

.LoginPageImage {
    width: 60%;
}

.LoginLogoImage {
    display: flex;
    text-align: center;
    margin-bottom: 60px;

}

fieldset {
    border-top: 1px solid #FFFF;
    border-bottom: none;
    border-left: none;
    border-right: none;
    display: block;
    text-align: center;
    color: grey;
}

fieldset legend {
    padding: 0px 10px;
}


.RecuperarSenha {
    color: grey;
    text-align: center;
}

.RecuperarSenha span,
.Registrar span {
    color: #00B0FF;
    cursor: pointer;
    margin-left: 10px;
}

.Registrar {
    color: grey;
    text-align: center;
}

.LineSeparator {
    margin: 25px 0;
    font-weight: 400;
    font-family: "Montserrat";
    font-style: normal;
}

@media (max-width: 1150px) {
    .a-page-login .background-black {
        right: -4%;
    }
}

@media (max-width: 920px) {
    .a-page-login {
        background-color: #252525;
        overflow: visible;
    }

    .LoginLogoImage {
        margin-bottom: 30px;
    }

    .a-page-login .coluna-A {
        display: none;
    }

    .a-page-login .coluna-B {
        width: 100%;
    }
    
    .a-page-login .background-black {
        display: none;
    }
}

@media (max-width: 375px) {
    #login {
        width: 300px;
    }

    .LoginLogoImage {
        width: 100%;
    }
}