.privacy-policy {
    background-color: #252525;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat";
}

.privacy-policy .a-back-action {
    position: fixed;
    left: 28px;
    top: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.privacy-policy .a-back-action img {
    width: 25px;
}

.privacy-policy .a-back-action p {
    margin-left: 15px;
    font-size: 20px;
}

.a-privacy-policy {
    max-width: 1050px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.a-privacy-policy .header-privacy-policy {
    margin-top: 50px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.a-privacy-policy .header-privacy-policy h1 {
    margin-top: 20px;
    font-size: 32px;
    font-weight: 300;
}

.a-privacy-policy .content {
    color: #dddddd;
    margin-bottom: 60px;
}

.a-privacy-policy .content h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.a-privacy-policy .content h2:first-child {
    margin-top: auto;
}

.a-privacy-policy .content h2 {
    margin-top: 40px;
}

.a-privacy-policy .content span {
    font-weight: 700;
}

.a-privacy-policy .content ul {
    list-style-type: square;
}

.a-privacy-policy .content p,
.a-privacy-policy .content h3,
.a-privacy-policy .content ul {
    margin-left: 15px;
    line-height: 22px;
    text-align: justify;
}

.a-privacy-policy .content h3 {
    margin-bottom: 5px;
}

.a-privacy-policy .content h3 + ul {
    margin-left: 45px;
    margin-bottom: 15px;
}

.a-privacy-policy .content h3 + p {
    margin-left: 30px;
}

.privacy-policy footer {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: rgb(255 255 255 / 10%);
    padding-bottom: 20px;
    position: relative;
}

.privacy-policy footer .a-footer {
    display: flex;
    flex-direction: column;
}

.privacy-policy .a-footer p {
    text-align: center;
    font-size: 18px;
}

.privacy-policy .a-footer .footer-brands {
    margin-bottom: 30px;
}

.privacy-policy .a-footer .footer-brands {
    display: flex;
    justify-content: space-between;
}

.privacy-policy .a-footer .footer-brands img {
    height: 100px;
}

.privacy-policy footer .nav-top {
    position: absolute;
    right: 100px;
    bottom: 50%;
    margin-bottom: -30px;
    cursor: pointer;
}

.privacy-policy footer .nav-top img {
    width: 40px;
    transform: rotate(180deg);
}

@media (max-width: 1280px) {
    .privacy-policy .a-back-action p,
    .privacy-policy footer .nav-top {
        display: none;
    }
}

@media (max-width: 1080px) {
    .a-privacy-policy {
        max-width: 900px;
    }
}

@media (max-width: 940px) {
    .a-privacy-policy {
        max-width: 95%;
    }
}

@media (max-width: 667px) {
    .a-privacy-policy .header-privacy-policy img {
        height: 100px;
    }

    .a-privacy-policy .header-privacy-policy h1 {
        font-size: 28px;
    }

    .a-privacy-policy .content h2 {
        font-size: 18px;
    }

    .a-privacy-policy .content p,
    .a-privacy-policy .content h3,
    .a-privacy-policy .content li {
        font-size: 15px;
    }

    .privacy-policy footer .a-footer {
        width: 80%;
    }

    .privacy-policy .a-footer .footer-brands img {
        height: 80px;
    }

    .privacy-policy .a-footer p {
        line-height: 24px;
        font-size: 16px;
    }
}

@media (max-width: 380px) {
    .a-privacy-policy .header-privacy-policy img {
        height: 94px;
    }

    .a-privacy-policy .header-privacy-policy h1 {
        font-size: 24px;
    }

    .privacy-policy .a-footer .footer-brands img {
        height: 70px;
    }
}